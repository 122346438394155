import {useState, useEffect} from "react";

export default asyncFn => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const serviceCall = async () => {
    setLoading(true);
    try {
      setData(await asyncFn());
    } catch (error) {
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timeout = "";
    if (errorMsg) {
      timeout = setTimeout(() => {
        setErrorMsg("");
      }, 4000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [errorMsg]);

  return {
    data,
    loading,
    error: {
      status: !!errorMsg,
      message: errorMsg,
    },
    serviceCall,
  };
};
